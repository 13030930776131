import {
  HOMEPAGE,
  VK_CHAT_JOIN_LINK,
  OK_CHAT_JOIN_LINK,
  OK_GROUP_ID,
} from "@/config/constants";
import kotbreadLightLogo from "@img/kotbreadLightLogo.png";
import kotbreadPurpleLogo from "@img/kotbreadPurpleLogo.png";
import sheepRoyaleLogo from "@img/sheepRoyaleLogo.png";
import profileBackground from "@img/background/profile.png";
import ratingBackground from "@img/background/rating.png";
import shopBackground from "@img/background/shop.png";
import magicChest from "@img/chests/magic/image.png";
import commonChest from "@img/chests/common/image.png";
import glow from "@img/chests/glow.png";
import legendaryChest from "@img/chests/legendary/image.png";
import coin from "@img/icon/coin.png";
import video from "@img/icon/video.png";
import giftIcon from "@img/icon/gift.png";
import gift from "@img/gift.png";
import trophy from "@img/icon/trophy.png";
import lightning from "@img/icon/lightning.png";
import speedometer from "@img/icon/speedometer.png";
import playcoin from "@img/icon/playcoin.png";
import corn from "@img/icon/corn.png";
import common from "@img/icon/common.png";
import rare from "@img/icon/rare.png";
import epic from "@img/icon/epic.png";
import lega from "@img/icon/lega.png";
import friends from "@img/icon/friends.webp";
import statistics from "@img/icon/statistics.png";
import profile from "@img/icon/profile.png";
import emotions from "@img/icon/emotions.webp";
import defaultIcon from "@img/icon/default.png";
import bee from "@img/statuses/bee.png";
import sheep from "@img/statuses/sheep.png";
import friendsLock from "@img/friendsLock.png";
import chain from "@img/chain.png";
import globeOutline from "@img/icon/globeOutline.png";
import groupOutline from "@img/icon/groupOutline.png";
import messageOutline from "@img/icon/messageOutline.png";
import miniappsOutline from "@img/icon/miniappsOutline.png";
import gridTile from "@img/background/gridTile.png";
import magicWandOutline from "@img/icon/magicWandOutline.png";
import tgStar from "@img/icon/tgStar.png";

import panelHeaderBackground from "@img/panels/profile/panelHeaderBackground.png";
export const vk_urls = {
  group: "https://vk.com/sheep_royale",
  chat: VK_CHAT_JOIN_LINK,
  game: "https://vk.com/sheeproyale",
};
export const ok_urls = {
  group: `https://ok.ru/group/${OK_GROUP_ID}`,
  chat: OK_CHAT_JOIN_LINK,
  game: "https://ok.ru/game/512002010758",
};

export const tg_urls = {
  group: "https://t.me/sheeproyale2_game",
  chatRU: "https://t.me/sheeproyale2_chat_ru",
};

export const images = {
  kotbreadLogo: {
    light: kotbreadLightLogo,
    purple: kotbreadPurpleLogo,
  },
  sheepRoyaleLogo: sheepRoyaleLogo,
  background: {
    profile: profileBackground,
    rating: ratingBackground,
    shop: shopBackground,
    gridTile,
  },
  cards: {
    masks: {
      "1": HOMEPAGE + "/img/cards/lock/parts/0.svg",
      "2": HOMEPAGE + "/img/cards/lock/parts/1.svg",
      "3": HOMEPAGE + "/img/cards/lock/parts/2.svg",
      "4": HOMEPAGE + "/img/cards/lock/parts/3.svg",
      legendary: HOMEPAGE + "/img/cards/card_mask_legendary.svg",
    },
    strokes: {
      "1": HOMEPAGE + "/img/cards/lock/strokes/0.svg",
      "2": HOMEPAGE + "/img/cards/lock/strokes/1.svg",
      "3": HOMEPAGE + "/img/cards/lock/strokes/2.svg",
      "4": HOMEPAGE + "/img/cards/lock/strokes/3.svg",
      legendary: HOMEPAGE + "/img/cards/card_under_legendary.svg",
    },
  },
  panels: {
    profile: {
      deck: {
        background: HOMEPAGE + "/img/panels/profile/deck/bg.svg",
        leftTab: HOMEPAGE + "/img/panels/profile/deck/leftTabBg.svg",
        middleTab: HOMEPAGE + "/img/panels/profile/deck/middleTabBg.svg",
        rightTab: HOMEPAGE + "/img/panels/profile/deck/rightTabBg.svg",
      },
      headerBackground: panelHeaderBackground,
    },
  },
  emotions: {
    bubble: HOMEPAGE + "/img/emotions/bubble.svg",
    centerBubble: HOMEPAGE + "/img/emotions/centerBubble.svg",
  },

  magicChest: magicChest,
  commonChest: commonChest,
  glow: glow,
  legendaryChest: legendaryChest,
  gift,
};

export const icons = {
  coin: coin,
  gift: giftIcon,
  trophy: trophy,
  lightning: lightning,
  speedometer: speedometer,
  corn: corn,
  common: common,
  rare: rare,
  epic: epic,
  lega: lega,
  profile: profile,
  default: defaultIcon,
  statistics: statistics,
  magicWandOutline: magicWandOutline,
  tgStar: tgStar,
  playcoin,
  globeOutline,
  groupOutline,
  messageOutline,
  miniappsOutline,
  friends,
  emotions,
  video,
};

export const lockIcons = {
  chain,
  inviteFriend: friendsLock,
};

export const sounds = {
  mainBackgrounds: [
    HOMEPAGE + "/sound/main_bg_01.mp3",
    HOMEPAGE + "/sound/main_bg_02.mp3",
    HOMEPAGE + "/sound/main_bg_03.mp3",
  ],
  soundEffects: HOMEPAGE + "/sound/sound_effects.mp3",
  loadingSound: HOMEPAGE + "/sound/loading_sound.mp3",
  menuButton: HOMEPAGE + "/sound/menu_button.mp3",
  tabbarButton: HOMEPAGE + "/sound/tabbar_button.mp3",
  cardPopup: HOMEPAGE + "/sound/card_popup.mp3",
  cardReplace: HOMEPAGE + "/sound/card_replace.mp3",

  sheepHits: [
    HOMEPAGE + "/sound/game/SheepHit_01.mp3",
    HOMEPAGE + "/sound/game/SheepHit_02.mp3",
    HOMEPAGE + "/sound/game/SheepHit_03.mp3",
  ],

  beeHits: [
    HOMEPAGE + "/sound/game/Bee_Hit_01.mp3",
    HOMEPAGE + "/sound/game/Bee_Hit_02.mp3",
    HOMEPAGE + "/sound/game/Bee_Hit_03.mp3",
  ],

  electroTower: [
    HOMEPAGE + "/sound/game/Electrotower_01.mp3",
    HOMEPAGE + "/sound/game/Electrotower_02.mp3",
    HOMEPAGE + "/sound/game/Electrotower_03.mp3",
  ],

  ghostSheep: [HOMEPAGE + "/sound/game/GhostSheep_Hit.mp3"],

  farmBuilds: [
    HOMEPAGE + "/sound/game/FarmBuild_01.mp3",
    HOMEPAGE + "/sound/game/FarmBuild_02.mp3",
    HOMEPAGE + "/sound/game/FarmBuild_03.mp3",
  ],
  pastureBuilds: [
    HOMEPAGE + "/sound/game/PastureBuild_01.mp3",
    HOMEPAGE + "/sound/game/PastureBuild_02.mp3",
    HOMEPAGE + "/sound/game/PastureBuild_03.mp3",
  ],
  doubleFarmBuilds: [
    HOMEPAGE + "/sound/game/DoubleFarmBuild_01.mp3",
    HOMEPAGE + "/sound/game/DoubleFarmBuild_02.mp3",
  ],
  crashedPlaneBuild: HOMEPAGE + "/sound/game/CrashedPlaneBuild.mp3",
  icePeakBuild: HOMEPAGE + "/sound/game/IcePeakBuild.mp3",
  icePeakDestroy: HOMEPAGE + "/sound/game/IcePeakDestroy.mp3",
  potionBuild: HOMEPAGE + "/sound/game/PotionSpawn.mp3",
  potionEffect: HOMEPAGE + "/sound/game/PotionEffect.mp3",
  deadFarmBuilds: [
    HOMEPAGE + "/sound/game/DeadFarmBuild_01.mp3",
    HOMEPAGE + "/sound/game/DeadFarmBuild_02.mp3",
  ],
  buildingsDestroy: HOMEPAGE + "/sound/game/BuildingsDestroy.mp3",
  mortarDestroy: HOMEPAGE + "/sound/game/MortarDestroy.mp3",
  coreHitGround: HOMEPAGE + "/sound/game/Core_HitGround.mp3",
  mortarShoot: HOMEPAGE + "/sound/game/MortarShoot.mp3",
  mortarBuild: HOMEPAGE + "/sound/game/MortarBuild.mp3",

  gameStart: HOMEPAGE + "/sound/game/start.mp3",
  gameEnd: HOMEPAGE + "/sound/game/end.mp3",

  legendaryChestOpenSound: HOMEPAGE + "/sound/Lootbox_Legendary.mp3",
  magicChestOpenSound: HOMEPAGE + "/sound/Lootbox_Magic.mp3",
  commonChestOpenSound: HOMEPAGE + "/sound/Lootbox_Regular.mp3",

  invisibleSheepSounds: {
    spawn: HOMEPAGE + "/sound/game/InvisibleSheep_Spawn.mp3",
    die: HOMEPAGE + "/sound/game/InvisibleSheep_Die.mp3",
  },

  moleDig: [
    HOMEPAGE + "/sound/game/Mole/Mole_Dig_01.mp3",
    HOMEPAGE + "/sound/game/Mole/Mole_Dig_02.mp3",
    HOMEPAGE + "/sound/game/Mole/Mole_Dig_03.mp3",
    HOMEPAGE + "/sound/game/Mole/Mole_Dig_04.mp3",
    HOMEPAGE + "/sound/game/Mole/Mole_Dig_05.mp3",
    HOMEPAGE + "/sound/game/Mole/Mole_Dig_06.mp3",
  ],

  moleSpawn: [
    HOMEPAGE + "/sound/game/Mole/Mole_Spawn_01.mp3",
    HOMEPAGE + "/sound/game/Mole/Mole_Spawn_02.mp3",
    HOMEPAGE + "/sound/game/Mole/Mole_Spawn_03.mp3",
  ],

  balloonBase: [
    HOMEPAGE + "/sound/game/Balloon/Balloon_Spawn.mp3",
    HOMEPAGE + "/sound/game/Balloon/Balloon_Explosion_01.mp3",
    HOMEPAGE + "/sound/game/Balloon/Balloon_Explosion_02.mp3",
    HOMEPAGE + "/sound/game/Balloon/Balloon_Explosion_03.mp3",
  ],

  cycloneSheep: [
    HOMEPAGE + "/sound/game/CycloneSheep/Cyclone_FadeIn.mp3",
    HOMEPAGE + "/sound/game/CycloneSheep/Cyclone_FadeOut.mp3",
    HOMEPAGE + "/sound/game/CycloneSheep/Cyclone_Loop.mp3",
  ],

  emotions: {
    beeGiveUp: [
      HOMEPAGE + "/sound/emotions/beeGiveUp_1.mp3",
      HOMEPAGE + "/sound/emotions/beeGiveUp_2.mp3",
      HOMEPAGE + "/sound/emotions/beeGiveUp_3.mp3",
    ],
    beeGoodLuck: [
      HOMEPAGE + "/sound/emotions/beeGoodLuck_1.mp3",
      HOMEPAGE + "/sound/emotions/beeGoodLuck_2.mp3",
      HOMEPAGE + "/sound/emotions/beeGoodLuck_3.mp3",
    ],
    beeWait: [
      HOMEPAGE + "/sound/emotions/beeWait_1.mp3",
      HOMEPAGE + "/sound/emotions/beeWait_2.mp3",
      HOMEPAGE + "/sound/emotions/beeWait_3.mp3",
    ],
    moleSneer: [
      HOMEPAGE + "/sound/emotions/moleSneer_1.mp3",
      HOMEPAGE + "/sound/emotions/moleSneer_2.mp3",
      HOMEPAGE + "/sound/emotions/moleSneer_3.mp3",
    ],
    sheepAngry: [
      HOMEPAGE + "/sound/emotions/sheepAngry_1.mp3",
      HOMEPAGE + "/sound/emotions/sheepAngry_2.mp3",
      HOMEPAGE + "/sound/emotions/sheepAngry_3.mp3",
    ],
    sheepSad: [
      HOMEPAGE + "/sound/emotions/sheepSad_1.mp3",
      HOMEPAGE + "/sound/emotions/sheepSad_2.mp3",
      HOMEPAGE + "/sound/emotions/sheepSad_3.mp3",
    ],
    sheepSorry: [
      HOMEPAGE + "/sound/emotions/sheepSorry_1.mp3",
      HOMEPAGE + "/sound/emotions/sheepSorry_2.mp3",
      HOMEPAGE + "/sound/emotions/sheepSorry_3.mp3",
    ],
    sheepyHello: [
      HOMEPAGE + "/sound/emotions/sheepyHello_1.mp3",
      HOMEPAGE + "/sound/emotions/sheepyHello_2.mp3",
      HOMEPAGE + "/sound/emotions/sheepyHello_3.mp3",
    ],
  },
};

interface EmojiIcons {
  [key: string]: string;
}

export const emojiStatuses: EmojiIcons = {
  sheep,
  bee,
};
