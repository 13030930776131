import UserReducer from "./reducers/user.reducer";
import profilePanelReducer from "./reducers/panels/profile.panel.reducer";
import AppReducer from "./reducers/app.reducer";
import StatusReducer from "./reducers/status.reducer";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import lobbyPanelReducer from "./reducers/panels/lobby.panel.reducer";
import {
  TypedUseSelectorHook,
  useSelector as useReduxSelector,
} from "react-redux";
import InterfaceReducer from "./reducers/interface.reducer";

const PanelsReducer = combineReducers({
  profile: profilePanelReducer,
  lobby: lobbyPanelReducer,
});

const rootReducer = combineReducers({
  User: UserReducer,
  App: AppReducer,
  Status: StatusReducer,
  Panels: PanelsReducer,
  Interface: InterfaceReducer,
});

const store = configureStore({
  reducer: rootReducer,
  devTools: true,
});

export default store;

export type RootDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;
export const useSelector: TypedUseSelectorHook<RootState> = useReduxSelector;
