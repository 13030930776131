import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IChestTypes } from "@/panels/ChestOpening/prize";
import { CardRarities } from "@/data/cards";
import { request } from "@/utils/request";
import { IUnitCard } from "@/types/unitCard";
import { EmotionType, IEmotionCard } from "@/types/emotionCard";
import { IStatus } from "./status.reducer";
import { APP_LANG } from "@/config/constants";

type StatsState = {
  wins: number;
  losses: number;
  draws: number;
};

export type Currency = "coins" | "voices" | "ok" | "stars";

export interface IDonateCurrency {
  voices?: number;
  coins?: number;
  ok?: number;
  stars?: number;
}
export interface IEmotionPrice {
  voices?: number;
  coins?: number;
  ok?: number;
  stars?: number;
}

export type Bonuses = {
  daily: number;
  subGroup?: boolean;
  joinChat?: boolean;
  notificationApp?: boolean;
  notificationBot?: boolean;
  addToFavorites?: boolean;
  addToHomeScreen?: boolean;
  visitPlayDeck?: boolean;
  rewardedGift: {
    lastUpdate: number;
    adWatchedTimes: number;
  };
};

type HistoryState = {
  id: number;
  userResponse: {
    rating: number;
    chest: IChestTypes;
    status: "win" | "lose" | "draw";
  };
  opponentInfo: {
    id: number;
    photo_200: string;
  };
  date: number;
};

export type IChest = {
  type: IChestTypes;
  willOpenAt?: number;
  status: "opening" | "idle";
  adWatchedTimes: number;
} | null;

export type Lottery = {
  endsAt: number;
  postLink: string;
  imageLink: string;
} | null;

export interface UserStatus {
  status: IStatus;
  isSelected: boolean;
  isGiftReceived: boolean;
}

export type UserState = {
  selfData: any;
  _id: number;
  name: string;
  platform: string;
  balance: number;
  stats: StatsState;
  photo_200: string;
  photo_100: string;
  history: HistoryState[] | [];
  isToken?: boolean;
  chests: [IChest, IChest, IChest, IChest];
  lobby?: {
    _id: number;
    url: string;
  };
  bonuses: Bonuses;
  rating: number;
  isTutorialPassed: boolean;
  shop: IShop;
  isShopLoading: boolean;
  lottery?: Lottery;

  referrals?: {
    refId?: string;
    referralsCount: number;
  };

  statuses: UserStatus[];

  units: {
    deck: IUnitCard[];
    collection: IUnitCard[];
  };
  emotions: {
    deck: IEmotionCard[];
    collection: IEmotionCard[];
  };

  settings: Settings;
};

type Settings = {
  isMuted: boolean;
};

export type Coin = Extract<
  StoreProducts,
  StoreProducts.coins | StoreProducts.heapCoins | StoreProducts.bigHeapCoins
>;

export interface IShop {
  boughtProducts: StoreProducts[];
  chest: { chestType: IChestTypes | null; currency: Currency };
  cardFragment: { card: string | null; currency: Currency };
  prices: {
    coins: Record<Coin, { voices: number; ok: number; stars: number }>;
    chests: Record<IChestTypes, IDonateCurrency>;
    cards: Record<CardRarities, IDonateCurrency>;
    emotions: Array<{
      _id: EmotionType;
      price: IEmotionPrice;
    }>;
  };
  nextShopUpdate: number;
  promotion?: {
    texts: {
      ru: {
        title: string;
        description: string;
      };
      en: {
        title: string;
        description: string;
      };
    };
    endsAt: number;
    discounts: {
      voices: number;
      coins: number;
      ok: number;
    };
  };
  serverDateNow: number;
}

export type ShopPrices = IShop["prices"];

const initialState: UserState = {
  statuses: [],
  _id: 0,
  name: "",
  platform: "",
  photo_100: "",
  photo_200: "",
  balance: 0,
  history: [],
  stats: {
    wins: 0,
    losses: 0,
    draws: 0,
  },
  chests: [null, null, null, null],
  bonuses: {
    daily: 0,
    rewardedGift: {
      lastUpdate: 0,
      adWatchedTimes: 0,
    },
  },
  rating: 0,
  isTutorialPassed: false,
  shop: {
    boughtProducts: [],
    chest: { chestType: null, currency: "coins" },
    cardFragment: { card: null, currency: "coins" },
    prices: {
      coins: {
        coins: { voices: 0, ok: 0, stars: 0 },
        heapCoins: { voices: 0, ok: 0, stars: 0 },
        bigHeapCoins: { voices: 0, ok: 0, stars: 0 },
      },
      chests: {
        common: { coins: 0, voices: 0, ok: 0, stars: 0 },
        magic: { coins: 0, voices: 0, ok: 0, stars: 0 },
        legendary: { coins: 0, voices: 0, ok: 0, stars: 0 },
      },
      cards: {
        common: { coins: 0, voices: 0, ok: 0, stars: 0 },
        rare: { coins: 0, voices: 0, ok: 0, stars: 0 },
        epic: { coins: 0, voices: 0, ok: 0, stars: 0 },
        legendary: { coins: 0, voices: 0, ok: 0, stars: 0 },
      },
      emotions: [],
    },
    nextShopUpdate: 0,
    serverDateNow: Date.now(),
  },
  isShopLoading: false,
  lottery: undefined,

  referrals: {
    refId: undefined,
    referralsCount: 0,
  },

  units: { deck: [], collection: [] },
  emotions: { deck: [], collection: [] },

  settings: {
    isMuted: true,
  },
};

export enum StoreProducts {
  discontCard = "discontCard",
  discontChest = "discontChest",
  discontEmotion = "discontEmotion",
  magicChest = "magicChest",
  commonChest = "commonChest",
  legendaryChest = "legendaryChest",
  coins = "coins",
  heapCoins = "heapCoins",
  bigHeapCoins = "bigHeapCoins",
  emotion = "emotion_",
}

const transfromOpenTime = (obj: IChest[]) => {
  const now = Date.now();
  obj.forEach((chest) => {
    if (!chest || chest.status !== "opening" || chest.willOpenAt === undefined)
      return;
    chest.willOpenAt += now;
  });
};

export const fetchShop = createAsyncThunk(
  "User/setShop",
  async function (_, { dispatch }) {
    const shopInfo = await request<IShop>({
      method: "shop/get",
      params: { getShop: true },
    });

    const serverTimeDif = shopInfo.serverDateNow - Date.now();
    // Переформатируем время под локальное
    shopInfo.nextShopUpdate -= serverTimeDif;

    if (shopInfo.promotion?.endsAt) shopInfo.promotion.endsAt -= serverTimeDif;

    dispatch(
      setShop({
        ...shopInfo,
        nextShopUpdate: shopInfo.nextShopUpdate,
      })
    );
  }
);

const UserSlice = createSlice({
  name: "User",
  initialState,
  reducers: {
    setUser(state: any, action: PayloadAction<any>) {
      if (action.payload?.chests) transfromOpenTime(action.payload.chests);
      Object.keys(action.payload).forEach((key) => {
        state[key as keyof UserState] = action.payload[key as keyof UserState];
      });
    },
    setUserStatuses(state: UserState, action: PayloadAction<UserStatus[]>) {
      state.statuses = action.payload;
    },
    setBalance(state: UserState, action: PayloadAction<number>) {
      state.balance = action.payload;
    },
    setBonuses(state: UserState, action: PayloadAction<Bonuses>) {
      state.bonuses = action.payload;
    },
    setChestStatus(
      state: UserState,
      action: PayloadAction<{
        index: number;
        status: "empty" | "opening";
      }>
    ) {
      const chest = state.chests[action.payload.index];
      if (chest === null) return;

      if (action.payload.status === "opening") {
        state.chests[action.payload.index] = {
          status: action.payload.status,
          type: chest.type,
          adWatchedTimes: chest.adWatchedTimes,
        };
      }

      if (action.payload.status === "empty") {
        state.chests[action.payload.index] = null;
      }
    },
    setChests(state: UserState, action: PayloadAction<UserState["chests"]>) {
      transfromOpenTime(action.payload);
      state.chests = action.payload;
    },
    setUserLobby(
      state: UserState,
      action: PayloadAction<{ url: string; _id: any }>
    ) {
      state.lobby = action.payload;
    },
    completeTutorial(state: UserState) {
      state.isTutorialPassed = true;
    },
    startTutorial(state: UserState) {
      state.isTutorialPassed = false;
    },
    setShop(state: UserState, action: PayloadAction<IShop>) {
      state.shop = action.payload;
      if (action.payload.nextShopUpdate)
        state.shop.nextShopUpdate += Date.now();
    },
    setDiscontProducts(
      state: UserState,
      action: PayloadAction<{
        chest: IShop["chest"];
        cardFragment: IShop["cardFragment"];
      }>
    ) {
      state.shop.chest = action.payload.chest;
      state.shop.cardFragment = action.payload.cardFragment;
    },
    setBoughtProducts(
      state: UserState,
      action: PayloadAction<StoreProducts[]>
    ) {
      state.shop.boughtProducts = action.payload;
    },
    setShopLoading(state: UserState, action: PayloadAction<boolean>) {
      state.isShopLoading = action.payload;
    },
    setLottery(state: UserState, action: PayloadAction<Lottery>) {
      state.lottery = action.payload;
      if (state.lottery) {
        state.lottery.endsAt += Date.now();
      }
    },
    setActiveStatus(state: UserState, action: PayloadAction<string>) {
      state.statuses = state.statuses.map((status) => {
        if (status.status.toString() === action.payload.toString()) {
          return { ...status, isSelected: true };
        } else {
          status.isSelected = false;
          return status;
        }
      });
    },
    removeStatus(state: UserState) {
      state.statuses = state.statuses.map((status) => {
        if (status.isSelected) {
          return { ...status, isSelected: false };
        }

        return status;
      });
    },
    markStatusAsSeen: (state, action: PayloadAction<number>) => {
      const index = action.payload;
      if (index >= 0 && index < state.statuses.length) {
        state.statuses[index].isSelected = true;
        state.statuses[index].isGiftReceived = true;
      }
    },

    setUnitsDeck(state: UserState, action: PayloadAction<IUnitCard[]>) {
      state.units.deck = action.payload;
    },
    setUnitsCollection(state: UserState, action: PayloadAction<IUnitCard[]>) {
      state.units.collection = action.payload;
    },
    swapUnitsCard(
      state: UserState,
      action: PayloadAction<{ deckCard: IUnitCard; collectionCard: IUnitCard }>
    ) {
      const deckIndex = state.units.deck.findIndex(
        (card) => card.entity === action.payload.deckCard.entity
      );
      const collectionIndex = state.units.collection.findIndex(
        (card) => card.entity === action.payload.collectionCard.entity
      );

      if (deckIndex < 0 || collectionIndex < 0)
        throw new Error("Card not found");

      state.units.deck[deckIndex] = action.payload.collectionCard;
      state.units.collection[collectionIndex] = action.payload.deckCard;
    },

    setEmotionsDeck(state: UserState, action: PayloadAction<IEmotionCard[]>) {
      state.emotions.deck = action.payload;
    },
    setEmotionsCollection(
      state: UserState,
      action: PayloadAction<IEmotionCard[]>
    ) {
      state.emotions.collection = action.payload;
    },
    swapEmotionsCard(
      state: UserState,
      action: PayloadAction<{
        deckIndex: number;
        collectionCard: IEmotionCard;
      }>
    ) {
      const { deckIndex, collectionCard } = action.payload;

      const collectionIndex = state.emotions.collection.findIndex(
        (card) => card._id === collectionCard._id
      );
      if (collectionIndex < 0) throw new Error("Card not found");

      state.emotions.deck.push({ ...collectionCard, position: deckIndex });
      state.emotions.collection = state.emotions.collection.filter(
        (card) => card._id !== collectionCard._id
      );

      const deckCard = state.emotions.deck.find(
        (card) => card.position === deckIndex
      );
      if (deckCard) {
        state.emotions.collection.push({ ...deckCard, position: null });
        state.emotions.deck = state.emotions.deck.filter(
          (card) => card.position !== deckIndex
        );
      }
    },
    setIsMuted(state: UserState, action: PayloadAction<{ isMuted: boolean }>) {
      state.settings.isMuted = action.payload.isMuted;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShop.pending, (state) => {
        state.isShopLoading = true;
      })
      .addCase(fetchShop.fulfilled, (state) => {
        state.isShopLoading = false;
      });
  },
});

export default UserSlice.reducer;

export const {
  setUser,
  setBalance,
  setChestStatus,
  setChests,
  setUserLobby,
  setBonuses,
  completeTutorial,
  setShop,
  setShopLoading,
  setBoughtProducts,
  setDiscontProducts,
  setLottery,
  startTutorial,
  setUserStatuses,
  markStatusAsSeen,
  setActiveStatus,
  removeStatus,
  setIsMuted,
} = UserSlice.actions;

export const {
  setUnitsDeck,
  setUnitsCollection,
  swapUnitsCard,
  setEmotionsDeck,
  setEmotionsCollection,
  swapEmotionsCard,
} = UserSlice.actions;
